import React, { useContext } from 'react';
import { ThemeContext } from '../Style/ThemeContext';
import './DarkModeToggle.css';

function DarkModeToggle() {
  const { theme, toggleTheme } = useContext(ThemeContext);
  const isDarkMode = theme === 'dark';

  return (
    <div className={`toggle-switch ${isDarkMode ? 'toggle-switch-dark' : ''}`} onClick={toggleTheme}>
      <div className={`toggle-knob ${isDarkMode ? 'toggle-knob-dark' : ''}`}></div>
    </div>
  );
}

export default DarkModeToggle;
