import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import graphData from '../data/graphData.json'; // Assuming the JSON file is in the same directory
import './ExploreGraph.css';

const ExploreGraph = () => {
  const d3Container = useRef(null);

  useEffect(() => {
    if (d3Container.current) {
      const width = window.innerWidth;
      const height = window.innerHeight;

      const svg = d3.select(d3Container.current)
        .attr('width', width)
        .attr('height', height)
        .call(d3.zoom().on('zoom', (event) => {
          svg.attr('transform', event.transform);
        }))
        .append('g');

      const simulation = d3.forceSimulation(graphData.nodes)
        .force('link', d3.forceLink(graphData.links).id(d => d.id).distance(250)) // Increase link distance
        .force('charge', d3.forceManyBody().strength(-300)) // Decrease repulsion strength
        .force('center', d3.forceCenter(width / 2, height / 2))
        .force('collision', d3.forceCollide().radius(d => {
          switch (d.priority) {
            case 1: return 20;
            case 2: return 18;
            case 3: return 16;
            case 4: return 14;
            case 5: return 12;
            default: return 10;
          }
        }).strength(0.7)) // Add collision force with appropriate strength
        .on('tick', ticked);

        const link = svg.append('g')
        .attr('class', 'links')
        .selectAll('line')
        .data(graphData.links)
        .enter().append('line')
        .attr('class', 'link') // Add a class for additional CSS specificity if needed
        .attr('stroke-width', 2)
        .attr('opacity', 1)
        .attr('stroke', '#f4f4f4'); // Explicitly set the stroke color

      const colorScales = {
        Super: d3.scaleOrdinal(['#000000']),
        Physics: d3.scaleOrdinal(['#597445', '#658147', '#729762']),
        Chemistry: d3.scaleOrdinal(['#FFEC9E', '#FFBB70', '#ED9455', '#F7C566']),
        Biology: d3.scaleOrdinal(['#A5DD9B', '#C5EBAA', '#A4CE95']),
        Mathematics: d3.scaleOrdinal(['#973131', '#FF6969', '#BF3131', '#952323']),
        'Computer Science': d3.scaleOrdinal(['#5A639C', '#7776B3', '#9B86BD', '#E2BBE9']),
        Medicine: d3.scaleOrdinal(['#FF9EAA', '#FFD0D0', '#F075AA', '#FFCDEA']),
        Astronomy: d3.scaleOrdinal(['#102C57', '#26355D', '#2A629A', '#5F5D9C']),
        Economics: d3.scaleOrdinal(['#EF9C66', '#FF7D29', '#FF8A08', '#FB6D48']),
        Engineering: d3.scaleOrdinal(['#383838', '#565656', '#A9A9A9', '#C0C0C0']),
        Society: d3.scaleOrdinal(['#383838', '#565656', '#A9A9A9', '#C0C0C0']),
        Humanities: d3.scaleOrdinal(['#383838', '#565656', '#A9A9A9', '#C0C0C0']),
        'Nature Sciences': d3.scaleOrdinal(['#383838', '#565656', '#A9A9A9', '#C0C0C0']),
        Bioneering: d3.scaleOrdinal(['#383838', '#565656', '#A9A9A9', '#C0C0C0']),
        Chemineering: d3.scaleOrdinal(['#383838', '#565656', '#A9A9A9', '#C0C0C0']),
        Physineering: d3.scaleOrdinal(['#383838', '#565656', '#A9A9A9', '#C0C0C0']),
        Earthoneering: d3.scaleOrdinal(['#383838', '#565656', '#A9A9A9', '#C0C0C0']),
        'Social Humanities': d3.scaleOrdinal(['#383838', '#565656', '#A9A9A9', '#C0C0C0']),
        'Medical Physics': d3.scaleOrdinal(['#383838', '#565656', '#A9A9A9', '#C0C0C0']),
        'Physical Chemistry': d3.scaleOrdinal(['#383838', '#565656', '#A9A9A9', '#C0C0C0']),
        Biophysics: d3.scaleOrdinal(['#383838', '#565656', '#A9A9A9', '#C0C0C0']),
        Humaneering: d3.scaleOrdinal(['#383838', '#565656', '#A9A9A9', '#C0C0C0']),
      };

      const node = svg.append('g')
        .attr('class', 'nodes')
        .selectAll('circle')
        .data(graphData.nodes)
        .enter().append('circle')
        .attr('r', d => {
          switch (d.priority) {
            case 1: return 10;
            case 2: return 8;
            case 3: return 6;
            case 4: return 5;
            case 5: return 4;
            default: return 4;
          }
        })
        .attr('fill', d => colorScales[d.category](d.id))
        .attr('opacity', 1)
        .call(drag(simulation));

      node.append('title')
        .text(d => d.id);

      const labels = svg.append('g')
        .attr('class', 'labels')
        .selectAll('text')
        .data(graphData.nodes)
        .enter().append('text')
        .attr('dy', -10)
        .attr('text-anchor', 'middle')
        .attr('font-size', d => {
          switch (d.priority) {
            case 1: return '20px';
            case 2: return '18px';
            case 3: return '16px';
            case 4: return '14px';
            case 5: return '12px';
            default: return '12px';
          }
        })
        .text(d => d.id);

      function ticked() {
        link
          .attr('x1', d => d.source.x)
          .attr('y1', d => d.source.y)
          .attr('x2', d => d.target.x)
          .attr('y2', d => d.target.y);

        node
          .attr('cx', d => d.x)
          .attr('cy', d => d.y);

        labels
          .attr('x', d => d.x)
          .attr('y', d => d.y);
      }

      function drag(simulation) {
        return d3.drag()
          .on('start', (event, d) => {
            if (!event.active) simulation.alphaTarget(0.3).restart();
            d.fx = d.x;
            d.fy = d.y;
          })
          .on('drag', (event, d) => {
            d.fx = event.x;
            d.fy = event.y;
          })
          .on('end', (event, d) => {
            if (!event.active) simulation.alphaTarget(0);
            d.fx = null;
            d.fy = null;
          });
      }

      node.on('click', (event, d) => {
        const successors = graphData.links.filter(link => link.source.id === d.id);
        if (successors.length === 0) {
          alert(`Leaf node clicked: ${d.id}`);
        }
      });
    }
  }, []);

  return (
    <svg ref={d3Container}></svg>
  );
};

export default ExploreGraph;
