import React, { useState, useEffect, useContext } from "react";
import Sketch from "react-p5";
import { ThemeContext } from '../Style/ThemeContext'; // Import ThemeContext
import './ElementarParticles.css'; // Import the CSS file for custom styles

const word = "elementar";

const setup = (p5, canvasParentRef, font, setParticles) => {
  p5.createCanvas(800, 300).parent(canvasParentRef);

  const textSize = 64;
  const x = (p5.width - textSize * word.length * 0.6) / 2;
  const y = p5.height / 2;

  p5.textFont(font);
  p5.textSize(textSize);
  p5.fill(255);

  const points = font.textToPoints(word, x, y, 64, {
    sampleFactor: 0.15,
    simplifyThreshold: 0,
  });

  console.log("Points:", points);

  const newParticles = points.map((pt) => new Particle(p5, pt.x, pt.y));
  setParticles(newParticles);
};

const draw = (p5, particles, textColor) => {
  console.log("Drawing sketch...");
  p5.clear();
  particles.forEach((particle) => {
    particle.behaviors(p5, p5.mouseX, p5.mouseY);
    particle.update(p5);
    particle.show(p5, textColor);
  });
};

class Particle {
  constructor(p5, x, y) {
    this.p5 = p5;
    this.pos = p5.createVector(x, y);
    this.target = p5.createVector(x, y);
    this.vel = p5.createVector();
    this.acc = p5.createVector();
    this.r = p5.random(3, 5);
    this.maxspeed = 10;
    this.maxforce = 1;
    this.alpha = p5.random(0.6, 0.8);
  }

  behaviors(p5, mouseX, mouseY) {
    const mouse = p5.createVector(mouseX, mouseY);
    const flee = this.flee(p5, mouse);
    flee.mult(5);
    this.applyForce(flee);

    const arrive = this.arrive(p5, this.target);
    this.applyForce(arrive);
  }

  applyForce(f) {
    this.acc.add(f);
  }

  update(p5) {
    this.pos.add(this.vel);
    this.vel.add(this.acc);
    this.acc.mult(0);
  }

  show(p5, textColor) {
    p5.stroke(textColor.levels[0], textColor.levels[1], textColor.levels[2], this.alpha * 255);
    p5.strokeWeight(this.r);
    p5.point(this.pos.x, this.pos.y);
  }

  flee(p5, target) {
    const desired = p5.constructor.Vector.sub(target, this.pos);
    const d = desired.mag();
    if (d < 50) {
      desired.setMag(this.maxspeed);
      desired.mult(-1);
      const steer = p5.constructor.Vector.sub(desired, this.vel);
      steer.limit(this.maxforce);
      return steer;
    } else {
      return this.p5.createVector(0, 0);
    }
  }

  arrive(p5, target) {
    const desired = p5.constructor.Vector.sub(target, this.pos);
    const d = desired.mag();
    let speed = this.maxspeed;
    if (d < 100) {
      speed = this.p5.map(d, 0, 100, 0, this.maxspeed);
    }
    desired.setMag(speed);
    const steer = p5.constructor.Vector.sub(desired, this.vel);
    steer.limit(this.maxforce);
    return steer;
  }
}

const ElementarParticles = () => {
  const { theme } = useContext(ThemeContext); // Access theme from context
  const [font, setFont] = useState(null);
  const [particles, setParticles] = useState([]);
  const [p5Instance, setP5Instance] = useState(null); // State to hold p5 instance

  useEffect(() => {
    const loadFont = async () => {
      const p5Instance = new window.p5();
      const loadedFont = await new Promise((resolve, reject) => {
        p5Instance.loadFont(
          "https://cdnjs.cloudflare.com/ajax/libs/topcoat/0.8.0/font/SourceCodePro-Regular.otf",
          resolve,
          () => reject("Failed to load font.")
        );
      });
      setFont(loadedFont);
      setP5Instance(p5Instance); // Set the p5 instance
    };

    loadFont();
  }, []);

  if (!p5Instance) return <p>Loading...</p>; // Render loading state

  const textColor = theme === 'dark' ? p5Instance.color(252, 252, 249) : p5Instance.color(19, 52, 59);

  console.log("Current theme:", theme); // Log current theme
  console.log("Text color:", textColor.levels); // Log text color levels

  return (
    <div className="canvas-container">
      {font ? (
        <Sketch
        key={theme} // Force re-render when theme changes
        setup={(p5, canvasParentRef) => setup(p5, canvasParentRef, font, setParticles)}
        draw={(p5) => draw(p5, particles, textColor)}
      />
      ) : (
        <p>elementar</p>
      )}
    </div>
  );
};

export default ElementarParticles;
