// Home.js
import React from 'react';
import './Home.css';
import Searchbar from '../Components/Searchbar';
import ExploreButton from '../Components/ExploreButton';
import BackgroundAnimation from '../Components/BackgroundAnimation';
import MenuNavbar from '../Components/MenuNavbar';
import ElementarParticles from '../Components/ElementarParticles';
import { ThemeContext } from '../Style/ThemeContext';


function Home() {
  return (
          <div className="Start">

            
            <MenuNavbar />
            <div className='elementar-particles'><ElementarParticles /></div>
            <div className='searchbar-container'><Searchbar /></div>
            <div className='explorebutton-container'><ExploreButton /></div>
          </div>
  );
}

export default Home;
