import React from 'react';
import ExploreGraph from '../Components/ExploreGraph'; 
import './Explore.css';

const Explore = () => {
  return (
    <div className="explore-page">
      <ExploreGraph />
    </div>
  );
};

export default Explore;