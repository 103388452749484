import React from 'react';
import './MenuNavbar.css';
import DarkModeToggle from './DarkModeToggle';
import ProfileButton from './ProfileButton'
import { ThemeContext } from '../Style/ThemeContext';

function Navbar() {
  return (
    <div className="navbar">
      <a href="#about" className="navbar-link">About</a>
      <a href="#contribute" className="navbar-link">Contribute</a>
      <DarkModeToggle/>
      <ProfileButton/>
    </div>
  );
}

export default Navbar;