// src/Components/ExploreButton.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './ExploreButton.css';

const ExploreButton = () => {
  const [hovered, setHovered] = useState(false);
  const navigate = useNavigate(); // Hook for navigation

  // Define the list of colors for the rainbow effect
  const rainbowColors = [
    '#FF0000', // Red
    '#FF7F00', // Orange
    '#FFFF00', // Yellow
    '#00FF00', // Green
    '#0000FF', // Blue
    '#4B0082', // Indigo
    '#9400D3'  // Violet
  ];

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  const handleClick = () => {
    navigate('/explore'); // Navigate to /explore route
  };

  // Determine the dynamic class based on hover state
  const buttonClass = hovered ? 'explore-button hovered' : 'explore-button';

  // Select a random color from rainbowColors for the rainbow effect
  const randomColor = rainbowColors[Math.floor(Math.random() * rainbowColors.length)];

  // Inline style for the button (to dynamically change background color)
  const buttonStyle = hovered ? { backgroundColor: randomColor } : {};

  return (
    <button
      className={buttonClass}
      style={buttonStyle}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
    >
      Explore
    </button>
  );
};

export default ExploreButton;
