import React from 'react';
import './ProfileButton.css';
import { ReactComponent as Icon } from '../Icons/profile.svg'; // Adjust the path as necessary

function ProfileButton() {
  return (
    <button className="profile-button">
      <Icon className="profile-icon" />
    </button>
  );
}

export default ProfileButton;
